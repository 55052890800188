<template>
<div>
    <div v-if="valid" class="row">
      <div class="col-sm-12">
          <h2> Devsite for {{ info.domain }} </h2>
          <div class="row fat-row">
              <div class="col-3"> Devsite </div>
              <div class="col-6"> {{ info.virtname }} </div>
          </div>
          <div class="row fat-row">
              <div class="col-3"> VID </div>
              <div class="col-6"> {{ info.vid }} </div>
          </div>
          <div class="row fat-row">
              <div class="col-3"> Document Root </div>
              <div class="col-6"> {{ info.docroot }} </div>
          </div>
          <div class="row fat-row">
              <div class="col-3"> SSL </div>
              <div class="col-6"> {{ readable_ssl(info.ssl) }} </div>
          </div>
          <div class="row fat-row">
              <div class="col-3"> Originally Setup</div>
              <div class="col-6"> {{ info.setup_date }} </div>
              <input type="hidden" v-model="info.virtname">
          </div>
            <hr>
          <div class="form-group row">
            <label for='notes' class="col-sm-3">Special requests?</label>
            <div class="form-check form-check-inline col-sm-6">
                 <textarea class="col-9" rows="10" style="height:100%;" name="notes" v-model="notes"></textarea>
             </div>
          </div>

          <div v-if="warning != ''" class="row alert alert-danger"> {{ warning }} </div>

          <div class="form-group row fat-row notice-me">
               WARNING: promotion of the devsite to the live site will destroy the contents of the current live site:{{info.domain}}.<br>
               Please be sure to check for any posts, pages, eccomerce transactions, or events that may have occurred after the cloning of the devsite.
          </div>
          <div class="form-group row justify-content-center fat-row">

               <button v-on:click="promoteToLive" type="button" class="spaced btn btn-primary justify-content-center">Promote to Live</button>
               <GoBack title="Cancel"></GoBack>
          </div>
       </div>
    </div>
 </div>
</template>

<script>
   import axios from "axios";
   import { build_mail_message } from '@/helpers/utils.js';
   import { SPR_template, SPR_items, SPR_subject_template, SPR_subject_items } 
            from '@/helpers/mail_templates/SPR.js';
   import GoBack from "@/components/GoBack";
// TODO: Add an "I acknowledge this will destroy my current live site" button.
   export default {
      name: 'DevSiteInfo',
      components: { GoBack },
      emits: [ 'message' ],
      data () {
        return {
           vid: 0,
           status: false,
           valid: true,
           notes: '',
           info: [],
           virt_info: [],
           versions: [],
           warning: "",
           sendData: {  
               send_from: '',
               send_to: 'noc@swcp.com',
               footer: '', 
               message: ''  
           }         
        }
      },
      watch: {               
          current_user: function (newUser, oldUser) {
            this.$router.push({ name: "home"});
          }
      },                    
      methods: {
       send_message( msg, subject ) {
            var url = "/api/sendmessage";
            var success = "Your devsite promotion request has been submitted. <br>If you have any questions you can reach us at 505-232-7992 or help@swcp.com.";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };  
            this.sendData.send_from = this.customer_email;
            this.sendData.message = msg;
            this.sendData.subject = subject;
            this.sendData.footer = this.footer_tag;
            axios.post(url, this.sendData, config )
            .then (
                   response => {
                      this.response_status = response.data.status;
                      if (this.response_status) {
                          this.responseMessage('Devsite promotion Submitted', success, 'thankyou', 'info', '', [ ] );
                      } else {
                          this.responseMessage('Error sending registration', response.data.message, 'modal', 'serious', '', []);
                      }      
                   })
            .catch(error => {
                      if (error.response) {
                         if (error.response.data.status && error.response.data.status == "expired_token") {
                             this.$store.dispatch('expired');
                         }
                      }
                      this.response_status = error;
                      this.responseMessage('Problems with Request', error, 'modal', 'serious', '', []);
                   })
                    
       },           
       promoteToLive () {
           var subject, message, property, email;
           this.account = this.current_user;
               
           SPR_items.domain = this.info.domain;
           SPR_items.virtname = this.info.virtname;
           SPR_items.domain = this.info.domain;
           SPR_items.vid = this.info.vid;
           SPR_items.logname = this.current_user;
           SPR_items.notes = this.notes;
           SPR_subject_items.logname = this.current_user;
           SPR_subject_items.domain = this.info.domain;
      
           message = build_mail_message (SPR_template, SPR_items);
           subject = build_mail_message (SPR_subject_template, SPR_subject_items);
           this.send_message (message, subject);
       }, 

       readable_ssl (value) {
                 if (value == 0) { return "No"; }
                 if (value == 1) { return "SSL-only"; }
                 else { return "Yes"; } 
       },      

       responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body,
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           this.$emit('message');
           return;
       },

       buildInfo() {
           var config = { headers:
                               { Authorization: "Bearer " + this.jwt }
                        };
           this.info.vid = this.$route.params.vid;
           axios.get("/api/devsite/" + this.current_user + "/" + this.info.vid, config )
           .then (
                   response => {
                      if (response.data.status == 1) {
                         this.virt_info = response.data.data;
                         if (typeof this.virt_info.domain === 'undefined') {
                             this.valid = false;
                             this.responseMessage('VID not found', response.data.message, 'modal', 'serious', '', []);
                         } else {
                             this.info.domain = this.virt_info.domain;
                             this.info.virtname = this.virt_info.virtname;
                             this.info.ssl = this.virt_info.ssl;
                             this.info.docroot = this.virt_info.docroot;
                             this.info.setup_date = this.virt_info.setup_date;
                             this.info.vid = this.virt_info.vid;
                         }
                      } else {
                         this.responseMessage('Permissions problem, contact noc@swcp.com', response.data.message, 'modal', 'serious', '', []);
                      }
                   })
        }
      },

      mounted()  {
         this.buildInfo();
      },
      computed: {
          footer_tag () {
              if (this.operator === this.current_user) {
                 return "Message sent by " + this.operator;
              } else {
                 return "Message sent by " + this.operator + " for " + this.current_user;
              }       
          },                 
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          customer_email () { return this.$store.state.reply_to; },
          jwt ()             { return this.$store.state.jwt; }
      }
   }

</script>
<style>
.notice-me {
   margin-top: 5px;
   font-weight: bold;
   color: #ffffff;
   justify-content: center;
   background-color: #ec7143;
   padding: 10px;
   border-radius: 2em;
}              
.fat-row {
   padding-top: 7px;
   padding-bottom: 7px;
}
</style>
